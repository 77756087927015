import React, { FC, useCallback, useContext, useMemo } from "react";
import axios from "axios";
import { useAuth } from "../auth/authContext";
import { useQuery } from "@tanstack/react-query";
import { APIProvider } from "@vis.gl/react-google-maps";
import { toast } from "react-toastify";

export type TransactionEvent = {
  createdUtc: string;
  message: string;
};
export type Transaction = {
  id: string;
  amount: number;
  duration: number;
  siteName: string;
  startUtc: string;
  endUtc: string;
  status: string;
  type: string;
  events: TransactionEvent[];
};
export type Invoice = {
  id: string;
  transactionId: string;
  status: string;
  number: string;
  url: string;
};
export type Site = {
  siteId: string;
  city: string;
  siteName: string;
  siteAddress: string;
  latitude: number;
  longitude: number;
  currentCapacity: number;
  bayTypes: string[];
  siteTimeZone: string;
};
export type SiteParker = Site & {
  id: string;
  accessTimes: {
    id: string;
    areaId: string;
    dayOfWeek:
      | 0 // "Sunday"
      | 1 //"Monday"
      | 2 //"Tuesday"
      | 3 //"Wednesday"
      | 4 //"Thursday"
      | 5 //"Friday"
      | 6; //"Saturday";
    start: number;
    end: number;
  }[];
  areas: {
    id: string;
    name: string;
  }[];
};
export type Product = {
  name: string;
  description: string;
};
export type ReservationCreate = {
  siteId: string;
  city: string;
  siteName?: string;
};
export type Reservation = ReservationCreate & {
  id?: string;
  vehicleId?: string;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  error?: string;
  status?: string;
  price?: number;
  products?: Product[];
  bayType?: string;
  siteAddress?: string; // not used in the API, but useful for the UI
  siteTimezone?: string; // not used in the API, but useful for the UI
};
export type Vehicle = {
  id: string;
  parkerId: string;
  plate: string;
  isActive: boolean;
  make: string;
  model: string;
  colour: string;
  state: string;
  isElectric: boolean;
};
export type Parker = {
  id: string;
  uid: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  suburb: string;
  state: string;
  postcode: string;
  notificationType: "email" | "sms";
  agreeTermsConditions: Date | null;
  stripeMetadata?: string;
  createdUtc: string | null;
  isActive: boolean;
  isEmailVerified: boolean;
  paymentDetails: StripeMetadataItem[];
  hasValidPayment: boolean;
  hasExpiredPayment: boolean;
  bayPreference: string;
  permitNumber: string;
};
export type ReservationPriceAndStatus = {
  status: string;
  price: number;
  error: string;
  products: { name: string; description: string }[];
};
export type Config = { googleMapsApi?: string };

export type StripeMetadataItem = {
  Card: {
    Brand: string;
    Last4: string;
    ExpMonth: number;
    ExpYear: number;
  };
};

const spUrl = window.location.hostname.includes(".sharepark.net")
  ? window.location.hostname.replace("app", "api")
  : "localhost:5001";

const ApiContext = React.createContext<{
  reissueQrCode: () => Promise<void>;
  createReservation: (r: Reservation) => Promise<void>;
  getStatusAndPrice: (r: Reservation) => Promise<ReservationPriceAndStatus>;
  cancelReservation: (r: Reservation) => Promise<void>;
  editDetails: (d: Parker) => Promise<void>;
  editVehicle: (v: Vehicle) => Promise<void>;
  createVehicle: (v: Vehicle) => Promise<void>;
  deleteVehicle: (v: Vehicle) => Promise<void>;
  getInvoiceUrl: (id: string) => Promise<string | void>;
  setBayPreference: (bayPreference: string, permitNumber: string) => void;
  spUrl: string;
  config: Config;
  fetchReservations: () => Promise<Reservation[]>;
}>({
  reissueQrCode: async () => {},
  createReservation: async () => {},
  getStatusAndPrice: async () => {
    return { status: "", price: 0, error: "", products: [] };
  },
  cancelReservation: async () => {},
  editDetails: async () => {},
  editVehicle: async () => {},
  createVehicle: async () => {},
  deleteVehicle: async () => {},
  getInvoiceUrl: async () => "",
  setBayPreference: () => {},
  spUrl,
  config: {},
  fetchReservations: async () => [],
});

export const useApi = () => useContext(ApiContext);

export const ApiProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { token, currentUser, setProfile, checkValidToken, profile } =
    useAuth();

  const { data: config } = useQuery(["config"], async () => {
    const res = await axios.get("https://" + spUrl + "/public/config");
    return res.data;
  });

  const metadata = useMemo(() => {
    const metadata = (currentUser ? currentUser.toJSON() : {}) as Object & {
      token: string | null;
    };
    metadata.token = token;
    return metadata;
  }, [currentUser, token]);

  // *** Reservations API ***
  const reissueQrCode = useCallback(async () => {
    checkValidToken();

    axios
      .post("https://" + spUrl + "/public/reissueQrCode", metadata)
      .then((response) => {
        setProfile(response.data);
      })
      .catch((error) => {
        // TODO: handle error
      });
  }, [metadata, setProfile, checkValidToken]);

  // const editReservation = (reservation) => {
  //   console.log(reservation);
  //   axios.post('https://' + spUrl + '/public/updateReservation', { metadata, reservation } ).then(response => {
  //     setProfile(response.data);
  //   }).catch(error => {});
  // }

  const fetchReservations = useCallback(async () => {
    console.log("fetchReservations");

    checkValidToken();

    try {
      const response = await axios.post(
        `https://${spUrl}/public/reservations`,
        metadata
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching reservations:", error);
      throw new Error("Failed to fetch reservations");
    }
  }, [metadata, checkValidToken]);

  const createReservation = useCallback(
    async (reservation: Reservation) => {
      checkValidToken();

      axios
        .post("https://" + spUrl + "/public/createReservation", {
          metadata,
          reservation,
        })
        .then((response) => {})
        .catch((error) => {
          // TODO: handle error
        });
    },
    [metadata, checkValidToken]
  );

  const getStatusAndPrice = useCallback(
    async (reservation: ReservationCreate) => {
      checkValidToken();

      return axios
        .post("https://" + spUrl + "/public/getStatusAndPrice", {
          metadata,
          reservation,
        })
        .then((response) => {
          return response.data as ReservationPriceAndStatus;
        })
        .catch((error) => {
          // TODO: handle error

          return {
            status: "Unavailable",
            price: 0,
            error: "No Availability: " + error,
            products: [],
          };
        });
    },
    [metadata, checkValidToken]
  );

  const cancelReservation = useCallback(
    async (reservation: Reservation) => {
      checkValidToken();

      axios
        .post("https://" + spUrl + "/public/cancelReservation", {
          metadata,
          reservation,
        })
        .then((response) => {
          if (response.data.error) {
            reservation.error = response.data.error;
            toast.error(`Error cancelling reservation ${response.data.error}`);
          }
        })
        .catch((error) => {
          reservation.error = "Error cancelling reservation";
          toast.error("Error cancelling reservation");
        });
    },
    [metadata, checkValidToken]
  );

  // *** End Reservations API ***

  const getInvoiceUrl = useCallback(
    async (id: string) => {
      checkValidToken();

      return axios
        .get(`https://${spUrl}/public/getInvoiceUrl/${id}`)
        .then((response) => {
          return response.data as string;
        })
        .catch((error) => {});
    },
    [checkValidToken]
  );

  // *** Details API ***

  const editDetails = useCallback(
    async (parkerDetails: Parker) => {
      checkValidToken();

      axios
        .post("https://" + spUrl + "/public/updateDetails", {
          metadata,
          parkerDetails,
        })
        .then((response) => {
          setProfile(response.data);
        })
        .catch((error) => {});
    },
    [metadata, setProfile, checkValidToken]
  );

  const setBayPreference = useCallback(
    async (bayPreference: string, permitNumber: string) => {
      checkValidToken();

      axios
        .post("https://" + spUrl + "/public/setBayPreference", {
          metadata,
          bayPreference,
          permitNumber,
        })
        .then((response) => {
          console.log(response.data);

          if (profile) {
            // update the profile with the new bay preference and permit number
            // but make a copy, as we want to trigger a re-render
            // and the profile object is the same

            const newProfile = { ...profile };
            newProfile.parker.bayPreference = response.data.bayPreference;
            newProfile.parker.permitNumber = response.data.permitNumber;
            setProfile(newProfile);
          }
        })
        .catch((error) => {});
    },
    [metadata, setProfile, checkValidToken, profile]
  );

  // *** End Details API ***

  // *** Vehicle API ***
  const editVehicle = useCallback(
    async (vehicle: Vehicle) => {
      checkValidToken();

      if (profile) {
        // optimistically update the profile with the new vehicle details
        // but make a copy, as we want to trigger a re-render
        // and the profile object is the same

        const newProfile = { ...profile };

        if (!newProfile.vehicles) {
          newProfile.vehicles = [];
        }

        const vehicleUpdated = newProfile.vehicles.find(
          (v) => v.id === vehicle.id
        );

        if (vehicleUpdated) {
          vehicleUpdated.make = vehicle.make;
          vehicleUpdated.model = vehicle.model;
          vehicleUpdated.colour = vehicle.colour;
          vehicleUpdated.state = vehicle.state;
          vehicleUpdated.plate = vehicle.plate;
          vehicleUpdated.isElectric = vehicle.isElectric;
        }

        if (newProfile.parker) setProfile(newProfile);
      }

      axios
        .post("https://" + spUrl + "/public/updateVehicle", {
          metadata,
          vehicle,
        })
        .then((response) => {
          console.log(response.data);

          toast.success("Vehicle updated successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          // setProfile(response.data);
        })
        .catch((error) => {
          // TODO: handle error

          toast.error("Error updating vehicle", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    },
    [metadata, setProfile, checkValidToken]
  );

  const createVehicle = useCallback(
    async (vehicle: Vehicle) => {
      checkValidToken();

      axios
        .post("https://" + spUrl + "/public/createVehicle", {
          metadata,
          vehicle,
        })
        .then((response) => {
          setProfile(response.data);
        })
        .catch((error) => {
          // TODO: handle error
        });
    },
    [metadata, setProfile, checkValidToken]
  );

  const deleteVehicle = useCallback(
    async (vehicle: Vehicle) => {
      checkValidToken();

      axios
        .post("https://" + spUrl + "/public/deleteVehicle", {
          metadata,
          vehicle,
        })
        .then((response) => {
          setProfile(response.data);
        })
        .catch((error) => {
          // TODO: handle error
        });
    },
    [metadata, setProfile, checkValidToken]
  );
  // *** End Vehicle API ***

  const value = useMemo(
    () => ({
      reissueQrCode,
      //editReservation,
      createReservation,
      getStatusAndPrice,
      cancelReservation,
      editDetails,
      editVehicle,
      createVehicle,
      deleteVehicle,
      getInvoiceUrl,
      setBayPreference,
      spUrl,
      config,
      fetchReservations,
    }),
    [
      reissueQrCode,
      //editReservation,
      createReservation,
      getStatusAndPrice,
      cancelReservation,
      editDetails,
      editVehicle,
      createVehicle,
      deleteVehicle,
      getInvoiceUrl,
      setBayPreference,
      config,
      fetchReservations,
    ]
  );

  return (
    <ApiContext.Provider value={value}>
      {value.config?.googleMapsApi && (
        <APIProvider apiKey={value.config?.googleMapsApi}>
          {children}
        </APIProvider>
      )}
    </ApiContext.Provider>
  );
};
