import { useEffect } from "react";

const ServiceWorkerWrapper = () => {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
    }
  }, []);

  return null; // No UI needed since we're just unregistering the service worker
};

export default ServiceWorkerWrapper;
