import { Col, Container, Row, Table } from "react-bootstrap";
import { useProfile } from "../auth/authContext";
import { FC } from "react";
import { SiteParker } from "../api/shareparkApi";

const Locations = () => {
  const profile = useProfile();

  return (
    <Container className="px-5">
      <Row className="gx-2 my-5">
        <Col>
          <h2>Your Permanent Parking Locations</h2>
          <>
            {profile.permSites
              .filter((s) => s.accessTimes.length > 0)
              .map((siteParker) => (
                <ParkingLocation siteParker={siteParker} key={siteParker.id} />
              ))}
            {profile.permSites.filter((s) => s.accessTimes.length > 0)
              .length === 0 && (
              <div>You have no permanent parking locations</div>
            )}
          </>
        </Col>
      </Row>
    </Container>
  );
};

const dayOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
] as const;

const ParkingLocation: FC<{ siteParker: SiteParker }> = ({ siteParker }) => {
  const getTimeString = (time: number) => {
    if (time === 0) {
      return "Midnight";
    }

    if (time === 24) {
      return "Midnight";
    }

    if (time < 12) {
      return time + " AM";
    }

    if (time === 12) {
      return time + " PM";
    }

    return time - 12 + " PM";
  };

  const accessDays = siteParker.accessTimes;

  return (
    <Container className="g-3 my-3 py-2 bg-white reservation">
      <Row>
        <Col>{siteParker.siteName}</Col>
      </Row>
      <Row>
        <Col>{siteParker.siteAddress}</Col>
      </Row>

      {siteParker.areas.map((area, i) => {
        return (
          <div key={area.id}>
            {siteParker.areas.length > 1 && (
              <Row>
                <Col>Area: {area.name}</Col>
              </Row>
            )}

            <Row>
              <Col>
                <Table borderless size="sm" className="accessTimes">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {accessDays
                      .filter((acc) => acc.areaId === area.id)
                      .map((acc) => (
                        <tr key={acc.id}>
                          <td>{dayOfWeek[acc.dayOfWeek]}</td>
                          {acc.start === 0 && acc.end === 24 && (
                            <td>All Day</td>
                          )}
                          {acc.start === acc.end && (
                            <td className="text-danger">Not allowed</td>
                          )}
                          {(acc.start !== 0 || acc.end !== 24) &&
                            acc.start !== acc.end && (
                              <td>
                                {getTimeString(acc.start)} -{" "}
                                {getTimeString(acc.end)}
                              </td>
                            )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        );
      })}
    </Container>
  );
};

export default Locations;
