import QRCode from "qrcode.react";
import { useProfile } from "../auth/authContext";
import { useApi } from "../api/shareparkApi";
import { Container, Row, Col, Table } from "react-bootstrap";
import ButtonWrapper from "./ButtonWrapper";

const QRCodePanel = () => {
  const profile = useProfile();
  const { reissueQrCode } = useApi();

  return (
    <Container className="px-5 mb-2">
      <Row className="gx-2 my-5">
        <Col>
          <h2>Your QR Code</h2>
        </Col>
      </Row>
      <Row className="text-center">
        <Col className="mb-4 mb-md-0">
          {profile.parker.uid && (
            <QRCode
              value={profile.parker.uid}
              size={300}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
              renderAs={"svg"}
            />
          )}
        </Col>
        <Col xl={5} md={6} className="gx-3">
          <ButtonWrapper
            buttonText="Re-issue QR Code"
            fullWidth={true}
            onClick={() => reissueQrCode()}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default QRCodePanel;
